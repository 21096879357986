import '../styles/bootstrap.scss'
import '../styles/global.scss'
import '../styles/theme-dark.scss'
import { useTheme } from '../modules/theme'
import { config as fontawesomeConfig } from '@abs-warranty/fontawesome-svg-core'
import '@abs-warranty/fontawesome-svg-core/styles.css'
import React from 'react'

// https://github.com/vercel/next.js/issues/20682
// workaround for an issue with the <Head> tag on the Portal Phone conflicting with Font Awesome
fontawesomeConfig.autoAddCss = false

function MyApp({ Component, pageProps }) {
  // sets document.body class; listens for changes to browser/OS theme preference
  useTheme()

  return <Component {...pageProps} />
}

export default MyApp
